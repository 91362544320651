import { useEffect, useMemo, useState } from "react";
import styles from "./UserTable.module.css";
// import styles from "./AccountTable.module.css";
import { get, isArray } from "lodash";
// import { useAccounts } from "../../store/account/reducer";
import ShowUsersData from "./UserComponents/ShowUsersData";
import UserType from "./UserType";
import {
  Button,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { MdArrowDropDown } from "react-icons/md";
import { sortUser } from "./sortData";
// import { IAccount } from "../../types/account";
import { useDispatch } from "react-redux";
import { useUsers } from "../../store/user/reducer";
import { REQUEST_USERS } from "../../store/user/userActionType";
import { IUser } from "../../types/user";
export interface Users {
  searchQuery: string;
}

const UserTableMaster: React.FC<Users> = ({ searchQuery: query }) => {
  const { users, busy } = useUsers();
  const dispatch = useDispatch();
  if (!busy && !(users || []).length) {
    dispatch({ type: REQUEST_USERS, page: 1 });
  }

  const filterOption = useMemo(() => {
    return [
      {
        value: "oldest",
        label: "Oldest to Newest",
      },
      {
        value: "newest",
        label: "Newest To Oldest",
      },
      {
        value: "mostView",
        label: "Most Views",
      },
      {
        value: "leastView",
        label: "Least Views",
      },
      {
        value: "AtoZ",
        label: "First Name A - Z",
      },
      {
        value: "ZtoA",
        label: "First Name Z - A",
      },
      {
        value: "Suspended User",
        label: "Suspended User",
      },
    ];
  }, []);
  const [filterLabel, setFilterLabel] = useState<string>(
    "Select Filter Option"
  );
  const [filterValue, setFilterValue] = useState<string>("");
  const [sortValue, setSortValue] = useState<string>("");
  const [displayUsers, setDisplayUsers] = useState<IUser[] | []>([]);
  const searchColumns = useMemo(() => ["firstName", "lastName", "email"], []);

  useEffect(() => {
    setDisplayUsers(users);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    sortValueOnChange(sortValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const search = (user: Record<string, any>) => {
    return searchColumns.some((column) => {
      return (
        get(user, column, "")
          .toString()
          .toLowerCase()
          .indexOf(query.toLowerCase()) > -1
      );
    });
  };

  const sortValueOnChange = (value: string) => {
    if (value === "isPro") {
      setDisplayUsers(
        sortUser(users, filterValue)
          .filter(search)
          .filter((item: IUser) => (get(item, "isPro") ? item : null))
      );
    } else if (value === "free") {
      setDisplayUsers(
        sortUser(users, filterValue)
          .filter(search)
          .filter((item: IUser) => (get(item, "isPro") ? null : item))
      );
    } else {
      setDisplayUsers(sortUser(users, filterValue).filter(search));
    }
  };

  // if (!isArray(displayAccounts) && !displayAccounts.length) {
  //   return <p>Loading...</p>;
  // }

  return (
    <>
          <Flex className={styles.acTypeBtnDiv}>
        <HStack className={styles.CStack}>
          <UserType
            onChangeData={(data: any) => {
              setSortValue(data.name);
              sortValueOnChange(data.name);
            }}
          />
        </HStack>
      </Flex>
      <div className={styles.filterSection}>
        <Menu>
          <MenuButton
            as={Button}
            variant="ghost"
            padding="8px 8px 8px 10px"
            height="36px"
            style={{
              border: "1px solid black",
              backgroundColor: "white",
              width: "71%",
              borderRadius: "12px",
            }}
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Image
                // ml={3}
                width="13%"
                src="/Images/User/3filterIcon.png"
                alt="filter Icon"
              />
              <Text width="85%" fontSize="15px">
                {filterLabel}
              </Text>
            </Flex>
          </MenuButton>
          <MenuList>
            {filterOption.map((value: any) => {
              return (
                <MenuItem
                  key={value.value}
                  value={value.value}
                  className={styles.selectOpt}
                  onClick={() => {
                    setDisplayUsers(
                      sortUser(
                        isArray(displayUsers) && displayUsers.length
                          ? displayUsers.filter(search)
                          : users,
                        value.value
                      )
                    );
                    setFilterLabel(value.label);
                    setFilterValue(value.value);
                  }}
                >
                  {value.label}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </div>
      <div className={styles.headingOfTable}>
        <p className={styles.tableHeading}>First</p>
        <p className={styles.tableHeading}>Last</p>
        <p className={` ${styles.tableHeading} ${styles.tableHeadingViews} `}>
          Views
        </p>
      </div>
      <div className={styles.scrollDiv}>
        {isArray(displayUsers) && displayUsers.length
          ? displayUsers.filter(search).map((value: any, index: number) => {
              return <ShowUsersData props={value} index={index} key={index} />;
            })
          : isArray(users) && users.length
          ? "Nothing to show here"
          : "Loading..."}
      </div>
    </>
  );
};

export default UserTableMaster;
