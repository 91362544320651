import { useEffect, useState } from "react";
import styles from "./account.module.css";
import { Link, useSearchParams } from "react-router-dom";
import { Center, Heading, Input } from "@chakra-ui/react";
import AccountTableMaster from "../../Component/AccountTable/AccountTableMaster";

const AccountMaster = () => {
  const [searchQuery, setSearch] = useState<any>("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("email") !== null) {
      const data = searchParams.get("email");
      setSearch(data);
    }
  }, [searchParams]);

  return (
    <>
      <div className={styles.mainOuterDiv}>
        <div className={styles.MainInnerDiv}>
          <div className={styles.accountSticyDiv}>
            <Link to="/">
              <div className={styles.homeBtnDiv}>
                <img
                  className={styles.homeIcon}
                  src="/Images/User/2homebutton.png"
                  alt="home"
                ></img>
              </div>
            </Link>

            <div className={styles.header3Section}>
              <div className={styles.headerSection}>
                <div className={styles.centerheader}>
                  <img
                    className={styles.relativePageImage}
                    src="/Images/Home/1useraccount.png"
                    alt="Account"
                  ></img>
                  <p className={styles.pageTitle}>Accounts</p>
                </div>
              </div>
            </div>
            <Heading size="md" fontWeight="semibold" m={"0px 20px"}>
              <Center>
                <Input
                  id="search"
                  type="text"
                  backgroundColor={"white"}
                  value={searchQuery}
                  borderRadius={"10px"}
                  placeholder="Search..."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></Input>
              </Center>
            </Heading>

            <AccountTableMaster searchQuery={searchQuery} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountMaster;
