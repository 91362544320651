import React from "react";
import styles from "../UserTable.module.css";
import { AES } from "crypto-js";
import { Link } from "react-router-dom";
import { Center, Grid, GridItem } from "@chakra-ui/react";
// import { IAccount } from "../../../types/account";
import { IUser } from "../../../types/user";
import { get } from "lodash";
import {useState} from "react";

interface props {
  props: IUser;
  index: number;
}

const ShowUsersData: React.FC<props> = ({ props, index }) => {
  const normalUserToken =
    process.env.REACT_APP_AES_KEY !== undefined
      ? AES.encrypt(
          props._id.toString(),
          process.env.REACT_APP_AES_KEY
        ).toString()
      : props._id.toString();
  const tokenUser = window.btoa(normalUserToken);
  // console.log(props);

  return (
    <div className={styles.userMainDiv}>
      <div className={styles.userProfile}>
        <p className={styles.indexNumber}>{index + 1}</p>

        <div
          className={
            //@ts-ignore
            props.isPro ? styles.proProfileDetail : styles.profileDetail
          }
        >
          <div className={styles.userNames}>
            <Grid templateColumns="repeat(5, 1fr)" gap={1}>
              {/* <Center>
                <GridItem colSpan={1} h="auto" className={styles.particularDiv}>
                  <img
                    className={styles.profileImage}
                    src={
                      get(props, "profileImage.url")
                        ? get(props, "profileImage.url")
                        : "/Images/profile.png"
                    }
                    alt={props.firstName + props.lastName}
                  ></img>
                </GridItem>
              </Center> */}
              <GridItem colSpan={3} h="auto">
                <Grid
                  // templateRows='repeat(1, 1fr)'
                  templateColumns="repeat(1, 1fr)"
                  rowGap={1}
                >
                  <GridItem colSpan={1}>
                    <Grid
                      templateColumns="repeat(3, 1fr)"
                      fontWeight="bold"
                      // justifyItems="center"
                      gap={2}
                    >
                      <GridItem className={styles.mainText}>
                        {props.firstName}
                      </GridItem>
                      <GridItem className={styles.mainText}>
                        {props.lastName}
                      </GridItem>
                      {/* <GridItem className={styles.mainText}>
                        0
                        // {props.views}
                      </GridItem> */}
                    </Grid>
                  </GridItem>
                  <GridItem
                    className={styles.mainText}
                    colSpan={2}
                    // textAlign="center"
                    fontWeight="500"
                    fontSize="12px"
                    mt="2px"
                  >
                    {"Email: " + get(props, "email")}
                  </GridItem>
                </Grid>
              </GridItem>          
              <Center>
                <GridItem colSpan={1} h="auto">
                  <Link to={`user_details/${tokenUser}`}>
                    <div className={styles.viewBtn}>
                      <button className={styles.view}>View</button>
                    </div>
                  </Link>
                  {
                    //@ts-ignore
                    props.isPro ? <p className={styles.proShow}>Pro</p> : ""
                  }
                </GridItem>
              </Center>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowUsersData;
