import { useEffect, useMemo, useState } from "react";
import styles from "./AccountTable.module.css";
import { get, isArray } from "lodash";
import { useAccounts } from "../../store/account/reducer";
import ShowAccountsData from "./AccountComponents/ShowAccountsData";
import UserType from "./UserType";
import {
  Button,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { MdArrowDropDown } from "react-icons/md";
import { sortAccount } from "./sortData";
import { IAccount } from "../../types/account";
import { useDispatch } from "react-redux";
import { REQUEST_ACCOUNTS } from "../../store/account/accountActionType";
export interface Accounts {
  searchQuery: string;
}

const AccountTableMaster: React.FC<Accounts> = ({ searchQuery: query }) => {
  const { accounts, busy } = useAccounts();
  const dispatch = useDispatch();
  if (!busy && !(accounts || []).length) {
    dispatch({ type: REQUEST_ACCOUNTS, page: 0 });
  }

  const filterOption = useMemo(() => {
    return [
      {
        value: "oldest",
        label: "Oldest to Newest",
      },
      {
        value: "newest",
        label: "Newest To Oldest",
      },
      {
        value: "mostView",
        label: "Most Views",
      },
      {
        value: "leastView",
        label: "Least Views",
      },
      {
        value: "AtoZ",
        label: "First Name A - Z",
      },
      {
        value: "ZtoA",
        label: "First Name Z - A",
      },
      {
        value: "Suspended User",
        label: "Suspended User",
      },
    ];
  }, []);
  const [filterLabel, setFilterLabel] = useState<string>(
    "Select Filter Option"
  );
  const [filterValue, setFilterValue] = useState<string>("");
  const [sortValue, setSortValue] = useState<string>("");
  const [displayAccounts, setDisplayAccounts] = useState<IAccount[] | []>([]);
  const searchColumns = useMemo(
    () => ["firstName", "lastName", "user.email"],
    []
  );

  useEffect(() => {
    setDisplayAccounts(accounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  useEffect(() => {
    sortValueOnChange(sortValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const search = (account: Record<string, any>) => {
    return searchColumns.some((column) => {
      return (
        get(account, column, "")
          .toString()
          .toLowerCase()
          .indexOf(query.toLowerCase()) > -1
      );
    });
  };

  const sortValueOnChange = (value: string) => {
    if (value === "isPro") {
      setDisplayAccounts(
        sortAccount(accounts, filterValue)
          .filter(search)
          .filter((item: IAccount) => (get(item, "user.isPro") ? item : null))
      );
    } else if (value === "free") {
      setDisplayAccounts(
        sortAccount(accounts, filterValue)
          .filter(search)
          .filter((item: IAccount) => (get(item, "user.isPro") ? null : item))
      );
    } else {
      setDisplayAccounts(sortAccount(accounts, filterValue).filter(search));
    }
  };

  // if (!isArray(displayAccounts) && !displayAccounts.length) {
  //   return <p>Loading...</p>;
  // }

  return (
    <>
      <Flex className={styles.acTypeBtnDiv}>
        <HStack className={styles.CStack}>
          <UserType
            onChangeData={(data: any) => {
              setSortValue(data.name);
              sortValueOnChange(data.name);
            }}
          />
        </HStack>
      </Flex>
      <div className={styles.filterSection}>
        <Menu>
          <MenuButton
            as={Button}
            variant="ghost"
            rightIcon={<MdArrowDropDown />}
            style={{
              border: "1px solid black",
              backgroundColor: "white",
              width: "71%",
              borderRadius: "12px",
            }}
          >
            <Flex>
              <HStack>
                <Image
                  ml={3}
                  src="/Images/User/3filterIcon.png"
                  alt="filter Icon"
                />
                <Text>{filterLabel}</Text>
              </HStack>
            </Flex>
          </MenuButton>
          <MenuList width={"100%"}>
            {filterOption.map((value: any) => {
              return (
                <MenuItem
                  key={value.value}
                  value={value.value}
                  className={styles.selectOpt}
                  onClick={() => {
                    setDisplayAccounts(
                      sortAccount(
                        isArray(displayAccounts) && displayAccounts.length
                          ? displayAccounts.filter(search)
                          : accounts,
                        value.value
                      )
                    );
                    setFilterLabel(value.label);
                    setFilterValue(value.value);
                  }}
                >
                  {value.label}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </div>
      <div className={styles.headingOfTable}>
        <p className={styles.tableHeading}>First</p>
        <p className={styles.tableHeading}>Last</p>
        <p className={` ${styles.tableHeading} ${styles.tableHeadingViews} `}>
          Views
        </p>
      </div>
       
      <div className={styles.scrollDiv}>
        {isArray(displayAccounts) && displayAccounts.length
          ? displayAccounts.filter(search).map((value: any, index: number) => {
              return (
                <>
                  <ShowAccountsData props={value} index={index} key={index} />
                </>
              );
            })
          : isArray(accounts) && accounts.length
          ? "Nothing to show here"
          : "Loading..."}
      </div>
    </>
  );
};

export default AccountTableMaster;
