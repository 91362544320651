import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  CloseButton,
  Flex,
  IconButton,
  Image,
  Input,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { AES, enc } from "crypto-js";
import { get } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, createSearchParams } from "react-router-dom";
import {
  REQUEST_ACCOUNTS,
  REQUEST_UPDATE_ACCOUNT,
} from "../../../../store/account/accountActionType";
import { useAccounts } from "../../../../store/account/reducer";
import { useSuspendMessageType } from "../../../../store/suspendType/reducer";
import { REQUEST_SUSPEND_TYPE } from "../../../../store/suspendType/suspendTypeActionTypes";
import { REQUEST_MESSAGE_NULL } from "../../../../store/userActions/userActionsActionType";
import { IAccount } from "../../../../types/account";
import AccountDeleteConfirmation from "../../../modules/Account/deleteUser/AccountDeleteConfirmation";
import Suspend from "../../../modules/Account/Suspend/Suspend";
import UnSuspend from "../../../modules/Account/UnSuspend/UnSuspend";
import * as Yup from "yup";
import InternatNotes from "../../../modules/Account/Internal_Notes/InternatNotes";
import styles from "./AccountDetails.module.css";
import { Field, Form, Formik } from "formik";
import { MdEdit, MdVerified } from "react-icons/md";
import AccountVerifyConformation from "../AccountVerifyConformation";
import { FaUser } from "react-icons/fa";
import { RiUserStarFill } from "react-icons/ri";
import UserProConfirmation from "../../../modals/UserProConfirmation";

const addSchema = Yup.object({
  firstName: Yup.string().min(2, "Too Short!").required("Required"),
  lastName: Yup.string().min(2, "Too Short!").required("Required"),
});

const AccountDetails = () => {
  const { accounts, busy: accountBusy } = useAccounts();
  const { suspendMessageType, busy: suspendTypeBusy } = useSuspendMessageType();
  const [deleteData, setDeleteData] = useState<IAccount>();
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [proData, setProData] = useState<IAccount>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const toast = useToast();
  const [account, setAccount] = useState<IAccount>();
  const {
    isOpen: isProOpen,
    onOpen: onProOpen,
    onClose: onProClose,
  } = useDisclosure();
  const [isPro, setIsPro] = useState<boolean>(false);

  const openDeleteDataModal = (value: IAccount) => {
    setDeleteData(value);
    setIsDelete(true);
    onDeleteOpen();
  };
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isSuspendOpen,
    onOpen: onSuspendOpen,
    onClose: onSuspendClose,
  } = useDisclosure();
  const {
    isOpen: isUnSuspendOpen,
    onOpen: onUnSuspendOpen,
    onClose: onUnSuspendClose,
  } = useDisclosure();

  const [targetAccount, setTargetAccount] = useState<IAccount>();

  const {
    isOpen: isVerifyOpen,
    onOpen: onVerifyOpen,
    onClose: onVerifyClose,
  } = useDisclosure();

  const dispatch = useDispatch();
  useEffect(() => {
    if (!accountBusy && !(accounts || []).length) {
      dispatch({ type: REQUEST_ACCOUNTS });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!suspendTypeBusy && !(suspendMessageType || []).length) {
      dispatch({ type: REQUEST_SUSPEND_TYPE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const originalUrl = window.location.pathname.split("/");
    const aesToken = window.atob(originalUrl[originalUrl.length - 1]);
    const accountId =
      process.env.REACT_APP_AES_KEY !== undefined
        ? AES.decrypt(aesToken, process.env.REACT_APP_AES_KEY).toString(
            enc.Utf8
          )
        : aesToken.toString();
    setAccount(accounts.find((ac: IAccount) => ac._id === accountId));
  }, [accounts]);

  const getAuthToken = (userId: string) => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/auth/login_with_admin/${userId}`,
      withCredentials: true,
    })
      .then((res) => {
        if (get(res, "data.token")) {
          window.open(
            `${process.env.REACT_APP_FRONTEND_URL}/?token=${encodeURIComponent(
              res.data.token
            )}`,
            "_blank"
          );
        } else {
          setErrorMsg(
            "Something went wrong, please try again after some time or Refresh the Page."
          );
        }
      })
      .catch((error) => {
        setErrorMsg(
          get(error, "response.data.message") ??
            "Something went wrong, please try again after some time or Refresh the Page."
        );
      });
  };
  const initialValue = useMemo(() => {
    return {
      firstName: account?.firstName,
      lastName: account?.lastName,
    };
  }, [account]);

  if (accountBusy && !accounts.length) {
    return <div>loading</div>;
  } else if (!account) {
    return <div>account not found...</div>;
  }

  const onSubmit = (values: Record<string, any>) => {
    setIsDisabled(true);

    let payload: Record<string, any> = {
      firstName: values.firstName,
      lastName: values.lastName,
    };

    const data = { payload, _id: account._id };
    dispatch({
      type: REQUEST_UPDATE_ACCOUNT,
      data,
      onSuccess: () => {
        toast({
          title: "Account Updated.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
      onError: (message: string) => {
        toast({
          title: message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      },
    });
  };

  const openProDataModal = (value: IAccount) => {
    setProData(value);
    setIsPro(true);
    onProOpen();
  };
  const verifyAccount = (value: IAccount) => {
    setTargetAccount(value);
    onVerifyOpen();
  };
  // const navigate = useNavigate();

  console.log(account);

  return (
    <>
      {errorMsg ? (
        <Alert status="error" zIndex={100}>
          <AlertIcon />
          <AlertTitle mr={2}>{errorMsg}</AlertTitle>

          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setErrorMsg("")}
          />
        </Alert>
      ) : null}
      <div className={styles.mainOuterDiv}>
        <div className={styles.MainInnerDiv}>
          <div className={styles.homeBtnDiv}>
            <Link to="/">
              <Image
                className={styles.homeIcon}
                src="../../../.././Images/User/2homebutton.png"
                alt="home button"
              ></Image>
            </Link>
            <div className={styles.GgoBackDiv}>
              <Link to="">
                <p className={styles.goBackIcon}>Go Back</p>
              </Link>
            </div>
          </div>

          <div className={styles.profileImgDiv}>
            {moment(get(account, "user.suspend.suspendTill")).diff(
              moment(new Date())
            ) > 0 ? (
              <p className={styles.suspendedMessage}>(Suspended)</p>
            ) : (
              <p className={styles.activeMessage}>(Active)</p>
            )}
            <Image
              className={styles.profileImg}
              src={
                get(account, "profileImage.url")
                  ? get(account, "profileImage.url")
                  : "/Images/profile.png"
              }
              alt="view button"
            ></Image>
            <p className={styles.UserName}>
              {account.firstName + " " + account.lastName}
              {/* <IconButton
                aria-label="Verify"
                icon={
                  <MdVerified
                    color={get(account, "isVerify") ? "#3e8578" : "#98a1aa"}
                  />
                }
                marginRight={2}
                variant={"ghost"}
                onClick={() => {
                  verifyAccount(account);
                }}
              />
              <IconButton
                aria-label="edit"
                icon={<MdEdit />}
                variant={"ghost"}
                onClick={() => {
                  setIsDisabled(!isDisabled);
                }}
              />
              {get(account, "user.isPro") ? (
                <IconButton
                  style={{ cursor: "unset" }}
                  aria-label="Pro User"
                  icon={<RiUserStarFill />}
                  onClick={() => {
                    openProDataModal(account);
                  }}
                  marginLeft={2}
                />
              ) : (
                <IconButton
                  style={{ cursor: "unset" }}
                  aria-label="Pro User"
                  icon={<FaUser />}
                  marginLeft={2}
                  onClick={() => {
                    openProDataModal(account);
                  }}
                />
              )} */}
              {get(account, "user.isPro") ? (
                <div className={styles.proDiv}>
                  <p className={styles.proUser}>Pro</p>
                </div>
              ) : null}
              {get(account, "isVerify") ? (
                <div className={styles.proDiv}>
                  <p className={styles.verifyUser}>Verified</p>
                </div>
              ) : null}
            </p>
            <div className={styles.signUpDiv}>
              <p className={styles.signUp}> Signup </p>
              <p className={styles.signUpDate}>
                {get(account, "createdAt")
                  ? moment(account.createdAt).format("DD/MM/YYYY hh:mm A")
                  : "NaN"}
              </p>
               
            </div>
          </div>

          <div className={styles.scroolDiv}>
            <div className={styles.userDeepDetails}>
              <div className={styles.userDetailsInner}>
                <Formik
                  initialValues={initialValue}
                  validationSchema={addSchema}
                  onSubmit={onSubmit}
                >
                  {({ values, errors, touched }) => (
                    <Form>
                      <div className={styles.fandlNamediv}>
                        {errors.firstName &&
                          touched.firstName &&
                          errors.firstName}
                        {/* <Field name={"firstName"}>
                        {({ field }) => {
                          return (
                            <Input
                              borderRadius={"12px"}
                              disabled={isDisabled}
                              focusBorderColor="none"
                              className={` ${styles.fname} `}
                              value={values.firstName}
                              type={"text"}
                              {...field}
                            ></Input>
                          );
                        }}
                      </Field>
                      {errors.lastName && touched.lastName && errors.lastName}
                      <Field name={"lastName"}>
                        {({ field }) => {
                          return (
                            <Input
                              borderRadius={"12px"}
                              disabled={isDisabled}
                              focusBorderColor="none"
                              className={` ${styles.lname} `}
                              type={"text"}
                              {...field}
                            ></Input>
                          );
                        }}
                      </Field> */}

                        {/* <Button
                        type={"submit"}
                        disabled={accountBusy || isDisabled}
                      >
                        Save
                      </Button> */}
                        {/* <Text className={` ${styles.fname} ${styles.golabalEditing} `}>
                        {account.firstName}
                      </Text> */}
                        {/* <Text className={` ${styles.lname} ${styles.golabalEditing} `}>
                        {account.lastName}
                      </Text> */}
                      </div>
                    </Form>
                  )}
                </Formik>
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    className={` ${styles.maildiv} ${styles.golabalEditing} `}
                    style={{ width: "50%" }}
                  >
                    <Text w={"100%"} className={` ${styles.fname} `}>
                      {get(account, "firstName")}
                    </Text>
                  </div>
                  <div
                    className={` ${styles.maildiv} ${styles.golabalEditing} `}
                    style={{ width: "50%" }}
                  >
                    <Text w={"100%"} className={` ${styles.lname} `}>
                      {get(account, "lastName")}
                    </Text>
                  </div>
                </div>

                <div className={` ${styles.maildiv} ${styles.golabalEditing} `}>
                  <Flex alignItems="center" gap={2}>
                    <a href="mailto:">
                      <Image
                        src="/Images/User/emailIcon.png"
                        alt="Email"
                        boxSize="20px"
                        objectFit="cover"
                      />
                    </a>
                    <Text className={styles.email}>
                      {get(account, "user.email")}
                    </Text>
                  </Flex>
                </div>
                <div
                  className={` ${styles.numberdiv} ${styles.golabalEditing} `}
                >
                  <Flex alignItems="center" gap={1}>
                    <a href="tel:">
                      <Image
                        src="/Images/User/phoneIcon.png"
                        alt="Whatsapp"
                        boxSize="20px"
                        objectFit="cover"
                      />
                    </a>
                    <Text className={styles.number}>
                      {get(account, "user.phoneNumber")
                        ? get(account, "user.phoneNumber")
                        : "NaN"}
                    </Text>
                  </Flex>
                </div>
                <div
                  className={` ${styles.numberdiv} ${styles.golabalEditing} `}
                >
                  <Flex>
                    <label>Company: </label>
                    <Text
                      marginLeft={"2"}
                      className={styles.number}
                      fontWeight="normal"
                    >
                      Slynk
                    </Text>
                  </Flex>
                </div>
                <div
                  className={` ${styles.pageViewdiv} ${styles.golabalEditing} `}
                >
                  <Text className={styles.pageView}>
                    Page Views :
                    <span className={styles.viewrsNumber}>
                      {" "}
                      {account.views}
                    </span>
                  </Text>
                </div>
                <div
                  className={` ${styles.pageViewdiv} ${styles.golabalEditing} `}
                >
                  <Text className={styles.pageView}>
                    Tags :<span> {get(account, "tags.length")}</span>
                  </Text>
                </div>
                <div
                  className={` ${styles.pageViewdiv} ${styles.golabalEditing} `}
                >
                  <Text className={styles.pageView}>
                    Apple Wallet :<span> 0</span>
                  </Text>
                </div>
                <div className={styles.notesandDashboardDiv}>
                  {/* <button
                  className={` ${styles.notes} ${styles.golabalEditing} `}
                >
                  Internal Notes
                </button> */}
                  {/* <InternatNotes /> */}
                  <button
                    className={` ${styles.dashboard} ${styles.golabalEditing} `}
                    onClick={() => {
                      getAuthToken(get(account, "user._id"));
                    }}
                  >
                    Dashboard
                  </button>

                  {/* <button
                    className={` ${styles.userBtn} ${styles.golabalEditing} `}
                  > */}
                  <Link
                    to={{
                      pathname: "/users",
                      search: `?${createSearchParams({
                        email: get(account, "user.email"),
                      })}`,
                    }}
                    className={` ${styles.userBtn} ${styles.golabalEditing} `}
                    style={{ textAlign: "center" }}
                  >
                    User
                  </Link>
                  {/* </button> */}
                </div>
                {/* <div className={styles.MessageDiv}>
                <button
                  className={` ${styles.Message} ${styles.golabalEditing} `}
                >
                  Message
                </button>
              </div> */}
                <div className={styles.actionDiv}>
                  {/* <button
                  className={` ${styles.delete} ${styles.golabalEditing} `}
                  onClick={() => {
                    openDeleteDataModal(account);
                  }}
                >
                  Delete User
                </button> */}

                  <button
                    className={` ${styles.verifyBtn} ${styles.golabalEditing} `}
                    onClick={() => {
                      verifyAccount(account);
                    }}
                  >
                    {!account.isVerify ? "Verify" : "Unverify"}
                  </button>

                  {/* <button
                    className={` ${styles.suspend} ${styles.golabalEditing} `}
                    // onClick={() => {
                    //   dispatch({ type: REQUEST_MESSAGE_NULL });
                    //   onSuspendOpen();
                    // }}
                  >
                    Suspend Acc
                  </button> */}

                  {moment(get(account, "user.suspend.suspendTill")).diff(
                    moment(new Date())
                  ) <= 0 ? (
                    <button
                      className={` ${styles.suspend} ${styles.golabalEditing} `}
                      onClick={() => {
                        dispatch({ type: REQUEST_MESSAGE_NULL });
                        onSuspendOpen();
                      }}
                    >
                      Suspend Acc
                    </button>
                  ) : (
                    <button
                      className={` ${styles.unsuspend} ${styles.golabalEditing} `}
                      onClick={() => {
                        dispatch({ type: REQUEST_MESSAGE_NULL });

                        onUnSuspendOpen();
                      }}
                    >
                      Un-Suspend Acc
                    </button>
                  )}
                </div>
                <div className={styles.actionDiv1}>
                  <button
                    className={` ${styles.delete} ${styles.golabalEditing} `}
                    onClick={() => {
                      openDeleteDataModal(account);
                    }}
                  >
                    Delete User
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSuspendOpen && (
        <>
          <Suspend isOpen={isSuspendOpen} onClose={onSuspendClose} />
        </>
      )}
      {isUnSuspendOpen && (
        <>
          <UnSuspend
            isOpen={isUnSuspendOpen}
            onClose={onUnSuspendClose}
            accountData={account}
          />
        </>
      )}
      {isDelete && deleteData && (
        <AccountDeleteConfirmation
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
          account={deleteData}
        />
      )}

      {isVerifyOpen && targetAccount && (
        <AccountVerifyConformation
          isOpen={isVerifyOpen}
          onClose={onVerifyClose}
          account={targetAccount}
        />
      )}
      {isPro && proData && (
        <UserProConfirmation
          isOpen={isProOpen}
          onClose={onProClose}
          account={proData}
        />
      )}
    </>
  );
};

export default AccountDetails;
