import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  CloseButton,
  IconButton,
  Image,
  Input,
  Flex,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { AES, enc } from "crypto-js";
import { get } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";
// import {
//   REQUEST_ACCOUNTS,
//   REQUEST_UPDATE_ACCOUNT,
// } from "../../../../store/account/accountActionType";
import { REQUEST_USERS } from "../../../../store/user/userActionType";
// import { useAccounts } from "../../../../store/account/reducer";
import { useUsers } from "../../../../store/user/reducer";
import { useSuspendMessageType } from "../../../../store/suspendType/reducer";
import { REQUEST_SUSPEND_TYPE } from "../../../../store/suspendType/suspendTypeActionTypes";
import { REQUEST_MESSAGE_NULL } from "../../../../store/userActions/userActionsActionType";
import { IAccount } from "../../../../types/account";
import AccountDeleteConfirmation from "../../../modules/Account/deleteUser/AccountDeleteConfirmation";
import Suspend from "../../../modules/User/Suspend/Suspend";
import UnSuspend from "../../../modules/User/UnSuspend/UnSuspend";
import * as Yup from "yup";
import InternatNotes from "../../../modules/Account/Internal_Notes/InternatNotes";
import styles from "./UserDetails.module.css";
import { Field, Form, Formik } from "formik";
import { MdEdit, MdVerified } from "react-icons/md";
import AccountVerifyConformation from "../UserVerifyConformation";
import { FaUser } from "react-icons/fa";
import { RiUserStarFill } from "react-icons/ri";
import { IUser } from "../../../../types/user";
import UserDeleteConfirmation from "../../../modals/UserDeleteConfirmation";
import UserDeleteModal from "../../../modules/User/deleteUser/UserDeleteModal";
import UserVisitPopUp from "../../../modules/User/UserVisitPopup/UserVisitPopUp";
import UserProConfirmation from "../../../modules/User/UserProConfirmation/UserProConfirmation";
import UserRoleConfirmation from "../../../modules/User/UserRole/UserRoleConfirmation";
import AccountTableMaster from "../../../AccountTable/AccountTableMaster";

const addSchema = Yup.object({
  firstName: Yup.string().min(2, "Too Short!").required("Required"),
  lastName: Yup.string().min(2, "Too Short!").required("Required"),
});

const UserDetails = () => {
  const { users, busy: userBusy } = useUsers();
  const { suspendMessageType, busy: suspendTypeBusy } = useSuspendMessageType();
  const [deleteData, setDeleteData] = useState<IUser>();
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [proData, setProData] = useState<any>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const toast = useToast();
  const [user, setUser] = useState<IUser>();
  const [visit, setVisit] = useState<IUser>();
  const [isAssignRole, setIsAssignRole] = useState<boolean>(false);
  const [assignRoleData, setAssignRoleData] = useState<IUser>();

  const {
    isOpen: isAssignRoleOpen,
    onOpen: onAssignRoleOpen,
    onClose: onAssignRoleClose,
  } = useDisclosure();

  const openAssignRoleModal = (value: IUser) => {
    setAssignRoleData(value);
    setIsAssignRole(true);
    onAssignRoleOpen();
  };

  const {
    isOpen: isProOpen,
    onOpen: onProOpen,
    onClose: onProClose,
  } = useDisclosure();

  const [isPro, setIsPro] = useState<boolean>(false);

  const openProDataModal = (value: any) => {
    setProData(value);
    setIsPro(true);
    onProOpen();
  };

  const openDeleteDataModal = (value: IUser) => {
    setDeleteData(value);
    setIsDelete(true);
    onDeleteOpen();
  };
  const {
    isOpen: isPopUpOpen,
    onOpen: onPopUpOpen,
    onClose: onPopUpClose,
  } = useDisclosure();
  const popup = (value: IUser) => {
    setVisit(value);
    onPopUpOpen();
  };
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isSuspendOpen,
    onOpen: onSuspendOpen,
    onClose: onSuspendClose,
  } = useDisclosure();
  const {
    isOpen: isUnSuspendOpen,
    onOpen: onUnSuspendOpen,
    onClose: onUnSuspendClose,
  } = useDisclosure();

  const [targetAccount, setTargetAccount] = useState<IUser>();

  const {
    isOpen: isVerifyOpen,
    onOpen: onVerifyOpen,
    onClose: onVerifyClose,
  } = useDisclosure();

  const dispatch = useDispatch();
  useEffect(() => {
    if (!userBusy && !(users || []).length) {
      dispatch({ type: REQUEST_USERS });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!suspendTypeBusy && !(suspendMessageType || []).length) {
      dispatch({ type: REQUEST_SUSPEND_TYPE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const originalUrl = window.location.pathname.split("/");
    const aesToken = window.atob(originalUrl[originalUrl.length - 1]);
    const accountId =
      process.env.REACT_APP_AES_KEY !== undefined
        ? AES.decrypt(aesToken, process.env.REACT_APP_AES_KEY).toString(
            enc.Utf8
          )
        : aesToken.toString();
    setUser(users.find((ac: IAccount) => ac._id === accountId));
  }, [users]);

  const getAuthToken = (userId: string) => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/auth/login_with_admin/${userId}`,
      withCredentials: true,
    })
      .then((res) => {
        if (get(res, "data.token")) {
          window.open(
            `${process.env.REACT_APP_FRONTEND_URL}/?token=${encodeURIComponent(
              res.data.token
            )}`,
            "_blank"
          );
        } else {
          setErrorMsg(
            "Something went wrong, please try again after some time or Refresh the Page."
          );
        }
      })
      .catch((error) => {
        setErrorMsg(
          get(error, "response.data.message") ??
            "Something went wrong, please try again after some time or Refresh the Page."
        );
      });
  };
  const initialValue = useMemo(() => {
    return {
      firstName: user?.firstName,
      lastName: user?.lastName,
    };
  }, [user]);

  if (userBusy && !users.length) {
    return <div>loading</div>;
  } else if (!user) {
    return <div>User not found...</div>;
  }

  const onSubmit = (values: Record<string, any>) => {
    setIsDisabled(true);

    let payload: Record<string, any> = {
      firstName: values.firstName,
      lastName: values.lastName,
    };

    // const data = { payload, _id: user._id };
    // dispatch({
    //   type: REQUEST_UPDATE_ACCOUNT,
    //   data,
    //   onSuccess: () => {
    //     toast({
    //       title: "Account Updated.",
    //       status: "success",
    //       duration: 9000,
    //       isClosable: true,
    //     });
    //   },
    //   onError: (message: string) => {
    //     toast({
    //       title: message,
    //       status: "error",
    //       duration: 9000,
    //       isClosable: true,
    //     });
    //   },
    // });
  };

  const verifyAccount = (value: IUser) => {
    setTargetAccount(value);
    onVerifyOpen();
  };





  return (
    <>
      {errorMsg ? (
        <Alert status="error" zIndex={100}>
          <AlertIcon />
          <AlertTitle mr={2}>{errorMsg}</AlertTitle>

          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setErrorMsg("")}
          />
        </Alert>
      ) : null}
      <div className={styles.mainOuterDiv}>
        <div className={styles.MainInnerDiv}>
          <div className={styles.homeBtnDiv}>
            <Link to="/">
              <Image
                className={styles.homeIcon}
                src="../../../.././Images/User/2homebutton.png"
                alt="home button"
              ></Image>
            </Link>
            <div className={styles.GgoBackDiv}>
              <Link to="/users">
                <p className={styles.goBackIcon}>Go Back</p>
              </Link>
            </div>
          </div>

          <div className={styles.profileImgDiv}>
            {moment(get(user, "user.suspend.suspendTill")).diff(
              moment(new Date())
            ) > 0 ? (
              <p className={styles.suspendedMessage}>(Suspended)</p>
            ) : (
              <p className={styles.activeMessage}>(Active)</p>
            )}
            {/* <Image
              className={styles.profileImg}
              src={
                get(user, "profileImage.url")
                  ? get(user, "profileImage.url")
                  : "/Images/profile.png"
              }
              alt="view button"
            ></Image> */}
            <div className={styles.userNameProAdminDiv}>
              <p className={styles.UserName}>
                {user.firstName + " " + user.lastName}
                {/* <IconButton
                aria-label="Verify"
                icon={
                  <MdVerified
                    color={get(user, "isVerify") ? "#3e8578" : "#98a1aa"}
                  />
                }
                marginRight={2}
                variant={"ghost"}
                onClick={() => {
                  verifyAccount(user);
                }}
              />
              <IconButton
                aria-label="edit"
                icon={<MdEdit />}
                variant={"ghost"}
                onClick={() => {
                  setIsDisabled(!isDisabled);
                }}
              />
              {get(user, "user.isPro") ? (
                <IconButton
                  style={{ cursor: "unset" }}
                  aria-label="Pro User"
                  icon={<RiUserStarFill />}
                  onClick={() => {
                    openProDataModal(user);
                  }}
                  marginLeft={2}
                />
              ) : (
                <IconButton
                  style={{ cursor: "unset" }}
                  aria-label="Pro User"
                  icon={<FaUser />}
                  marginLeft={2}
                  onClick={() => {
                    openProDataModal(user);
                  }}
                />
              )} */}
              </p>
              {user.isPro ? (
                <div className={styles.proDiv}>
                  <p className={styles.proUser}>Pro</p>
                </div>
              ) : null}
              {user.userType != "USER" ? (
                <div className={styles.adminDiv}>
                  <p className={styles.adminUser}>Admin</p>
                </div>
              ) : null}
            </div>

            <div className={styles.signUpDiv}>
              <p className={styles.signUp}> Signup </p>
              <p className={styles.signUpDate}>
                {get(user, "createdAt")
                  ? moment(user.createdAt).format("DD/MM/YYYY hh:mm A")
                  : "NaN"}
              </p>
            </div>
          </div>

          <div className={styles.userDeepDetails}>
            <div className={styles.userDetailsInner}>
              {/* <Formik
                initialValues={initialValue}
                validationSchema={addSchema}
                onSubmit={onSubmit}
              >
                {({ values, errors, touched }) => (
                  <Form>
                    <div className={styles.fandlNamediv}>
                      {errors.firstName &&
                        touched.firstName &&
                        errors.firstName}
                      <Field name={"firstName"}> */}
              {/* @ts-ignore */}
              {/* {({ field }) => {
                          return (
                            <Input
                              borderRadius={"12px"}
                              disabled={isDisabled}
                              focusBorderColor="none"
                              className={` ${styles.fname} `}
                              value={values.firstName}
                              type={"text"}
                              {...field}
                            ></Input>
                          );
                        }}
                      </Field>
                      {errors.lastName && touched.lastName && errors.lastName}
                      <Field name={"lastName"}> */}
              {/* @ts-ignore */}
              {/* {({ field }) => {
                          return (
                            <Input
                              borderRadius={"12px"}
                              disabled={isDisabled}
                              focusBorderColor="none"
                              className={` ${styles.lname} `}
                              type={"text"}
                              {...field}
                            ></Input>
                          );
                        }}
                      </Field> */}
              {/* <Button type={"submit"} disabled={userBusy || isDisabled}>
                        Save
                      </Button> */}
              {/* <Text className={` ${styles.fname} ${styles.golabalEditing} `}>
                        {account.firstName}
                      </Text> */}
              {/* <Text className={` ${styles.lname} ${styles.golabalEditing} `}>
                        {account.lastName}
                      </Text> */}
              {/* </div>
                  </Form>
                )}
              </Formik> */}
              <Flex width="100%" justifyContent="space-between">
                <div className={styles.firstlastNameDiv}>
                  <Text
                    className={` ${styles.fNameDiv} ${styles.golabalEditing} `}
                  >
                    {get(user, "firstName")}
                  </Text>
                </div>
                <div className={styles.firstlastNameDiv}>
                  <Text
                    className={` ${styles.fNameDiv} ${styles.golabalEditing} `}
                  >
                    {get(user, "lastName")}
                  </Text>
                </div>
              </Flex>

              <div className={` ${styles.maildiv} ${styles.golabalEditing} `}>
                <Flex alignItems="center" gap={2}>
                  <a href="mailto:">
                    <Image
                      src="/Images/User/emailIcon.png"
                      alt="Email"
                      boxSize="20px"
                      objectFit="cover"
                    />
                  </a>
                  <Text className={styles.email}>{get(user, "email")}</Text>
                </Flex>
              </div>

              <div className={` ${styles.numberdiv} ${styles.golabalEditing} `}>
                <Flex alignItems="center" gap={1}>
                  <a href="tel:">
                    <Image
                      src="/Images/User/phoneIcon.png"
                      alt="Whatsapp"
                      boxSize="20px"
                      objectFit="cover"
                    />
                  </a>
                  <Text className={styles.number}>
                    {get(user, "phoneNumber")
                      ? get(user, "phoneNumber")
                      : "NaN"}
                  </Text>
                </Flex>
              </div>
              <div className={` ${styles.genderdiv} ${styles.golabalEditing} `}>
                <Text className={styles.gender}>
                  Gender :
                  <span>
                    {" "}
                    {get(user, "gender") ? get(user, "gender") : "NaN"}
                  </span>
                </Text>
              </div>
              <div className={` ${styles.DOBdiv} ${styles.golabalEditing} `}>
                <Text className={styles.DOB}>
                  DOB :
                  <span>
                    {" "}
                    {get(user, "DOB")
                      ? moment(user.DOB).format("DD/MM/YY")
                      : "NaN"}
                  </span>
                  {/* get(user, "DOB") */}
                </Text>
              </div>
              {/* <div
                className={` ${styles.pageViewdiv} ${styles.golabalEditing} `}
              >
                <Text className={styles.personalpageView}>
                  Personal Page Views :
                  <span className={styles.personalviewrsNumber}>
                    {" "}
                    0{user.views}
                  </span>
                </Text>
              </div> */}
              {/* <div
                className={` ${styles.businessViewdiv} ${styles.golabalEditing} `}
              >
                <Text className={styles.businesspageView}>
                  Business Page Views :
                  <span className={styles.businessviewrsNumber}>
                    {" "}
                    0{user.views}
                  </span>
                </Text>
              </div> */}
              <div className={` ${styles.DOBdiv} ${styles.golabalEditing} `}>
                <Text className={styles.DOB}>
                  Pro Expiration :
                  <span>
                    {" "}
                    {get(user, "subscriptionTill")
                      ? moment(user.subscriptionTill).format("DD/MM/YYYY")
                      : "NaN"}
                  </span>
                  {/* get(user, "DOB") */}
                </Text>
              </div>


              <div>
                
              </div>

              <div className={styles.personalBusinessBtn}>
             
                  <button className={styles.personalBtn}>Personal</button>
                
                  <button className={styles.businessBtn}>Business</button>
           
              </div>

              <div>
                {/* <button
                  className={` ${styles.notes} ${styles.golabalEditing} `}
                >
                </button> */}
                <InternatNotes />
               
                {/* <Link to={{
                      pathname: "/accounts",
                      search: `?${createSearchParams({
                        email: get(user, "email"),
                      })}`,
                    }}
                    className={` ${styles.dashboard} ${styles.golabalEditing} `}
                    style={{textAlign: "center"}}>
                      Accounts
                </Link>
            */}
              </div>
              {/* <div className={styles.MessageDiv}>
                <button
                  className={` ${styles.Message} ${styles.golabalEditing} `}
                >
                  Message
                </button>
              </div> */}
              <div className={styles.actionDiv}>
                <button
                  className={` ${styles.delete} ${styles.golabalEditing} `}
                  onClick={() => {
                    popup(user);
                  }}
                >
                  Pop Up
                </button>

                {moment(get(user, "user.suspend.suspendTill")).diff(
                  moment(new Date())
                ) <= 0 ? (
                  <button
                    className={` ${styles.suspend} ${styles.golabalEditing} `}
                    onClick={() => {
                      dispatch({ type: REQUEST_MESSAGE_NULL });
                      onSuspendOpen();
                    }}
                  >
                    Suspend User
                  </button>
                ) : (
                  <button
                    className={` ${styles.unsuspend} ${styles.golabalEditing} `}
                    onClick={() => {
                      dispatch({ type: REQUEST_MESSAGE_NULL });

                      onUnSuspendOpen();
                    }}
                  >
                    Un-Suspend User
                  </button>
                )}
              </div>
              <div className={styles.proAdminDiv}>
                {!user.isPro ? (
                  <button
                    className={` ${styles.pro} ${styles.golabalEditing} `}
                    onClick={() => {
                      openProDataModal(user);
                    }}
                  >
                    Pro
                  </button>
                ) : (
                  <button
                    className={` ${styles.pro} ${styles.golabalEditing} `}
                    onClick={() => {
                      openProDataModal(user);
                    }}
                  >
                    Unpro
                  </button>
                )}

                <button
                  className={` ${styles.admin} ${styles.golabalEditing} `}
                  onClick={() => {
                    openAssignRoleModal(user);
                  }}
                >
                  {user.userType === "USER" ? "Admin" : "User"}
                </button>
              </div>
              <div className={styles.deleteDiv}>
                <button
                  className={` ${styles.deleteUser} ${styles.golabalEditing} `}
                  onClick={() => {
                    openDeleteDataModal(user);
                  }}
                >
                  Delete User
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSuspendOpen && (
        <>
          <Suspend isOpen={isSuspendOpen} onClose={onSuspendClose} />
        </>
      )}
      {isUnSuspendOpen && (
        <>
          <UnSuspend
            isOpen={isUnSuspendOpen}
            onClose={onUnSuspendClose}
            accountData={user}
          />
        </>
      )}
      {isDelete && deleteData && (
        <UserDeleteModal
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
          user={deleteData}
        />
      )}
      {isPopUpOpen && visit && (
        <UserVisitPopUp
          isOpen={isPopUpOpen}
          onClose={onPopUpClose}
          user={visit}
        />
      )}

      {/* {isVerifyOpen && targetAccount && (
        <AccountVerifyConformation
          isOpen={isVerifyOpen}
          onClose={onVerifyClose}
          account={targetAccount}
        />
      )}*/}
      {isPro && proData && (
        <UserProConfirmation
          isOpen={isProOpen}
          onClose={onProClose}
          user={proData}
        />
      )}
      {isAssignRole && assignRoleData && (
        <UserRoleConfirmation
          isOpen={isAssignRoleOpen}
          onClose={onAssignRoleClose}
          user={assignRoleData}
        />
      )}
    </>
  );
};

export default UserDetails;
